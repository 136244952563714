import Axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Vue from 'vue'
import 'weui'
import weui from 'weui.js'
import App from './App.vue'
import router from './router'
import store from './store'


Vue.config.productionTip = false
const instance1 = Axios.create(
  {
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
    },
  }
);
instance1.interceptors.request.use(config => {
  const ctoken = sessionStorage.getItem('ctoken');
  if (ctoken) {
    Object.assign(config.headers, {
      ctoken,
    })
  } else {
    window.location.href = "/";
  }
  return config;
})
instance1.interceptors.response.use(
  res => {
    return res.data;
  },
)
const instance2 = Axios.create(
  {
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
    },
  }
);
instance2.interceptors.response.use(
  res => {
    return res.data;
  },
)
const instance3 = Axios.create(
  {
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
    },
  }
);
instance3.interceptors.response.use(
  res => {
    return res.data;
  },
)
Vue.directive('title', {//改变对应浏览器标签标题
  inserted: (el, binding) => {
    document.title = binding.value;
  }
})

Vue.prototype.$axios = instance1;
Vue.prototype.$axios2 = instance2;
Vue.prototype.$axios3 = instance3;
Vue.prototype.$weui = weui;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
