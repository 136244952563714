<template>
  <div class="home">
    <div v-title="'登录页'"></div>
    <div>正在登陆中.......</div>
</div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      phone: "",
      token: "",
    };
  },
  created() {
    const that = this;
    croods.customPlugin({
      action: "UserPlugin.login",
      params: {},
      success: function (res) {
        that.token = res.token;
        alert(that.token);
        that.checkRegister();
      },
      fail: function (res) {
      },
    });
  },
  methods: {
    //把code传给后台,得到openid
    checkRegister() {
      const data = {
        token: this.token,
      };
      this.$axios2.post("/cuser/checkRegister", data).then((res) => {
        if (res.code == 0) {
          sessionStorage.setItem("phone", res.resultData.phone);
          sessionStorage.setItem("ctoken", res.resultData.ctoken);
          this.$router.push({ path: "/user" });
        } else if (res.code === -1) {
          this.$weui.alert(
            "第一次使用，请先在智能存取柜柜机上扫描皖康码进行激活",
            function () {
              window.location.reload();
            },
            {
              title: "提示",
            }
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  background-color: #f5f5f5;
  position: absolute;
  z-index: 2000;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>